import cn from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./loader.module.css";

interface Props {
  visible: true;
  onClose: () => void;
}

const TEXT_WALL_PRELOADER = [
  "Beam (C) 2049 Merit Circle Global",
  "Subnet loaded: Avalanche",
  "This preview beta is not for general public release.",
];

const TEXT_WALL = [
  "######J                                                                                                                                          ^7YPB#&&@&&#BPY!:              .~?PB#&&@&&#BGY7^       ",
  "@@@@@@Y                                                                                                                                       ~YB@@@@@@@@@@@@@@@@&BJ^         ^5#@@@@@@@@@@@@@@@@BY^    ",
  "@@@@@@Y                                                                                                                                     !G@@@@@B5?!~^^~~!?5#@@@@@P~      Y@@@@@GJ7!~^^~!?5#@@@@@Y.  ",
  "@@@@@@Y      ....                         ....                         ....                            ...             ....               .P@@@@#J^             ~Y&@@@@Y    Y@@@@P:           .!B@@@@G. ",
  "@@@@@@Y :7YPB####BG5?~.             .~?5GB#####BPJ!:             .~?5GB####BPY!:  ?YJJYY:  ?YJJYY::75G####BGY!.   .!YGB#####G5?^         :B@@@@5.                 ^G@@@@G. :&@@@#.              .B@@@@J ",
  "@@@@@@GP@@@@@@@@@@@@@@#Y^         ~5#@@@@@@@@@@@@@@&G7.        ^Y#@@@@@@@@@@@@@&P~B@@@@@~  B@@@@@G&@@@@@@@@@@@#J^Y&@@@@@@@@@@@@@B?      .B@@@@J                     P@@@@5 ^@@@@#.               !&##&5 ",
  "@@@@@@@@@@@&#BB#&@@@@@@@@5:     ^P@@@@@@@&#BB##@@@@@@@#7     :5@@@@@@@@&#BB#&@@@@@@@@@@@^  G@@@@@@@@@#BB#@@@@@@@@@@@@@&#BB&@@@@@@@G:    ?@@@@P                      .#@@@@~ P@@@@B7:.             ..... ",
  "@@@@@@@@&Y!:.   .^7G@@@@@@#^   7&@@@@@#Y~:.   .^7G@@@@@@5   ^#@@@@@@BJ~..  .:~JB@@@@@@@@^  G@@@@@@@J^.  .:?&@@@@@@@@G!:.  .~P@@@@@@P    B@@@@~                       ?@@@@P .5@@@@@@#GPY?!~:.           ",
  "@@@@@@@5.           ~#@@@@@#: !@@@@@@5.           !&@@@@@5 .#@@@@@@?            ?@@@@@@@^  G@@@@@@7        ^&@@@@@@5         P@@@@@@^  .&@@@&:                       ^@@@@B   ^JG&@@@@@@@@@@&BG5?~:     ",
  "@@@@@@5              ^&@@@@@5 B@@@@@B~^~~~~~~~~~~~^5@@@@@@^J@@@@@@7              ?@@@@@@^  G@@@@@#          5@@@@@@^         ~@@@@@@!  :&@@@&.                       ^@@@@B      .^!?YPB#&@@@@@@@@&G?:  ",
  "@@@@@@^               P@@@@@B^@@@@@@@@@@@@@@@@@@@@@@@@@@@@7P@@@@@B               :&@@@@@^  G@@@@@G          J@@@@@&:         ~@@@@@@7   B@@@@~                       ?@@@@P             .:^~7JPB@@@@@&? ",
  "@@@@@@!               G@@@@@G:@@@@@@@@@@@@@@@@@@@@@@@@@@@@!5@@@@@#               ^@@@@@@^  G@@@@@G          J@@@@@&:         ~@@@@@@7   J@@@@P                       B@@@@JJYYY7                :J&@@@@J",
  "@@@@@@B.             7@@@@@@? G@@@@@&7^~~~~~~~~~~~~~~~~~~~ 7@@@@@@J              P@@@@@@^  G@@@@@G          J@@@@@&:         ~@@@@@@7   .B@@@@?                     5@@@@P!@@@@B                  !@@@@&",
  "@@@@@@@#!          .J@@@@@@G  ^&@@@@@B!          .YGPPPGP^  G@@@@@@5^          ^G@@@@@@@^  G@@@@@G          J@@@@@&:         ~@@@@@@7    ^#@@@@J                  .P@@@@B.:@@@@@!                 ~@@@@#",
  "@@@@@@@@@#Y7~^^^~75&@@@@@@P.   ^B@@@@@@BY7~^^^~75&@@@@@&7   .G@@@@@@@GJ!^^^^!JG@@@@@@@@@^  G@@@@@G          J@@@@@&:         ~@@@@@@7     :G@@@@B?:             :J&@@@@5.  J@@@@&?.             .7&@@@@7",
  "@@@@@@&@@@@@@@@@@@@@@@@@#?      .J#@@@@@@@@@@@@@@@@@@&5:      ?#@@@@@@@@@@@@@@@@@&&@@@@@^  G@@@@@G          J@@@@@&:         ~@@@@@@7       7B@@@@&GJ7~^::^^~7YG@@@@@G!     7&@@@@&PJ7~^^::^~!?P#@@@@&7 ",
  "@@@@@&^?G&@@@@@@@@@@@&G7.         .7P#@@@@@@@@@@@@&G?:         .7P#@@@@@@@@@@@#P?:B@@@@@~  B@@@@@G          J@@@@@&:         ~@@@@@@7         !5#@@@@@@@@@@@@@@@@@#Y~        .?G&@@@@@@@@@@@@@@@@@@BJ:  ",
  "!!!!!!   ^7J5PGGGPY?~:               .^7J5PGGGPY?~.               .~?YPGGGPY?~.   ~!!!!!.  ~!!!!!~          ^!!!!!!.         .!!!!!!:           .~?5B#&@@@@@&#G5?^.             :7YPB#&@@@@@&&BGY7^  ",
  " ",
  " ",
  "Loading crack BEAMteam.rar...",
  "Decrypting ICE...",
  "Injecting rogue code into beamOS...",
  "Stealthy data infiltration in progress...",
  "1588508497.dat<>【2ch】チラシの裏【書き込み規制】 (159)",
  " ",
  "Encryption breached; access granted...",
  " ",
  "Crack successful...",
  " ",
  "CLICK ANYWHERE OR PRESS ENTER TO CONTINUE",
];

export const Loader = ({ visible, onClose }: Props) => {
  const [visibleLinesCount, setVisibleLinesCount] = useState(0);
  const [hasShownPreloader, setHasShownPreloader] = useState(false);

  const textWall = !hasShownPreloader ? TEXT_WALL_PRELOADER : TEXT_WALL;
  const hasCompleted =
    visibleLinesCount === textWall.length && hasShownPreloader;

  useEffect(() => {
    if (!hasCompleted) return;

    const handleClick = () => {
      onClose();
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        onClose();
      }
    };

    document.addEventListener("click", handleClick);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose, hasCompleted]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (visibleLinesCount < textWall.length) {
        setVisibleLinesCount((prevCount) => prevCount + 1);
      }
    }, 50); // Adjust this interval duration for faster/slower rendering of lines

    if (!hasShownPreloader && visibleLinesCount === textWall.length) {
      setTimeout(() => setHasShownPreloader(true), 1000);
    }

    return () => clearInterval(interval);
  }, [visibleLinesCount, textWall, hasShownPreloader]);

  return (
    <div
      className={cn(styles.container, !visible ? styles.container__hidden : "")}
    >
      {textWall.slice(0, visibleLinesCount).map((line, index) => (
        <pre
          key={index}
          className={cn(
            styles.line,
            index + 1 === textWall.length ? styles.line__last : "",
            index < 18 && hasShownPreloader ? styles.line__small : ""
          )}
        >
          {line}
        </pre>
      ))}
    </div>
  );
};
