import React from 'react';

export const Terms = () => {
  return (
    <pre
      style={{
        maxWidth: '800px',
        whiteSpace: 'pre-wrap',
      }}
    >
      <p>Last Updated: 27 September 2024</p>
      <p>PLEASE READ THESE TERMS OF USE BEFORE USING THE WEBSITE.</p>
      <p>
        <strong>Acceptance of the Terms of Use</strong>
      </p>
      <p>
        These terms of use are entered into by and between you and Beam
        Interface, a Cayman Islands company (including all affiliates,
        collectively referred to as, "Beam", "we", "us", or "our"). The
        following terms and conditions, together with any documents they
        expressly incorporate by reference (collectively, these "Terms of Use"),
        govern your access to and use of any website published by Beam,
        including, but not limited to, any content, functionality, and services
        offered on or through https://www.onbeam.com or any sub-URL of such a
        website (collectively, the "Website").
      </p>
      <p>
        Please read the Terms of Use carefully before you start to use the
        Website. By using the Website or by clicking to accept or agree to the
        Terms of Use when this option is made available to you, you accept and
        agree to be bound and abide by these Terms of Use.
      </p>
      <p>
        If you do not agree to these Terms of Use, you must not access or use
        the Website.
      </p>
      <p>
        <strong>About the Website</strong>
      </p>
      <p>
        Our Website is primarily meant to function as information with respect
        to the Beam ecosystem, a gaming ecosystem consisting of technologies,
        projects, communities, organizations and persons, utilizing, relying on,
        securing, or developing (as applicable) the Beam network, protocols and
        applications deployed on Beam network and their related smart contract
        systems.
      </p>
      <p>
        To use parts of our Website, a user may need to use a third-party,
        non-custodial wallet software that allows the user to interact with
        public smart contracts and blockchains. We do not operate, maintain, or
        provide any wallets or wallet services. We will at no time have any
        custody or control of any crypto-assets that any user is interacting
        with. We are not a wallet provider, exchange, broker, lender or
        borrower.
      </p>
      <p>
        As part of our Website, you may from time to time gain access to
        decentralized open-source smart contracts deployed on public
        blockchains. Our interface is distinct and separate from any of the
        smart contracts that may be accessed through it, and is merely one of
        multiple means of accessing such smart contracts. Users can interact
        with the same smart contracts otherwise directly, including to develop
        and build their own user interfaces on top of such smart contracts.
      </p>
      <p>
        Our Website may change and grow in numbers, which may require that we
        include additional terms for certain new parts of the Website (which, in
        such event, will be construed as part of the "Website"). We reserve the
        right in our sole discretion to modify or discontinue any parts of the
        Website at any time and without any liability.
      </p>
      <p>
        <strong>Who May Use the Website</strong>
      </p>
      <p>
        The Website is offered and available to users who are 13 years of age or
        older. The Website is not intended for children under 13 years of age.
        By using the Website, you represent and warrant that you (i) are 13
        years of age or older, (ii) are not barred to use the Website under any
        applicable law, and (iii) are using the Website only for your own
        personal use. If you do not meet these requirements, you must not access
        or use the Website.
      </p>
      <p>
        You must also not be a person who is (a) subject to national or
        international sanctions or located or ordinarily resident in any country
        or region that is the subject of comprehensive country-wide or
        region-wide sanctions, embargo or equivalent by the United States of
        America, United Kingdom, European Union or the government of a user home
        country, or (b) listed by the United States of America, United Kingdom,
        European Union or otherwise designated on any list of prohibited or
        restricted parties or persons.
      </p>
      <p>
        <strong>Changes to the Terms of Use</strong>
      </p>
      <p>
        We may revise and update these Terms of Use from time to time in our
        sole discretion. All changes are effective immediately when we post
        them.
      </p>
      <p>
        Your continued use of the Website following the posting of revised Terms
        of Use means that you accept and agree to the changes. You are expected
        to check this page frequently so you are aware of any changes, as they
        are binding on you.
      </p>
      <p>
        <strong>Accessing the Website and Account Security</strong>
      </p>
      <p>
        We reserve the right to withdraw or amend the Website, and any service
        or material we provide on the Website, in our sole discretion without
        notice. We do not guarantee that our Website or any content on them will
        always be available or be interrupted. We will not be liable if for any
        reason all or any part of the Website is unavailable at any time or for
        any period. From time to time, we may restrict access to some parts of
        the Website, or entire Website, to users.
      </p>
      <p>You are responsible for:</p>
      <ul>
        <li>
          Making all arrangements necessary for you to have access to the
          Website; and
        </li>
        <li>
          Ensuring that all persons who access the Website through your internet
          connection are aware of these Terms of Use and comply with them.
        </li>
      </ul>
      <p>
        To access the Website or some of the resources it offers, you may be
        asked to provide certain registration details or other information. It
        is a condition of your use of the Website that all the information you
        provide on the Website is correct, current, and complete. You agree that
        all information you provide to use the Website, including, but not
        limited to, using any interactive features on the Website, is governed
        by our Privacy Policy, and you consent to all actions we may take with
        respect to your information that are consistent with our Privacy Policy.
      </p>
      <p>
        You should use particular caution when inputting personal information
        onto the Website on a public or shared computer so that others are not
        able to view or record your personal information.
      </p>
      <p>
        <strong>Intellectual Property Rights</strong>
      </p>
      <p>
        Unless otherwise indicated, the Website is our proprietary property and
        all source code, databases, functionality, software, website designs,
        information, audio, video, text, photographs, and graphics on the
        Website (collectively, the "Content") and the trademarks, service marks,
        and logos contained therein (collectively, the "Marks") are owned or
        controlled by us or licensed to us, and are protected by copyright,
        trademark and other intellectual property laws and international
        conventions. You are not permitted to use the Marks without the prior
        written consent of the owner of the Mark.
      </p>
      <p>
        Except as expressly provided herein, Beam and its licensors do not grant
        any express or implied license to the Website or the Content. You agree
        not to copy, reproduce, aggregate, republish, download, post, display,
        transmit, modify, rent, lease, loan, sell, assign, distribute, license,
        sublicense, sell, reverse engineer, create derivative works based on, or
        otherwise exploit for any commercial purposes whatsoever, the Website or
        the Content, without our express prior written permission.
      </p>
      <p>
        If you are eligible to use the Website, you are granted a limited
        license to access and use the Website and the Content to which you have
        properly gained access solely for your personal, non-commercial use. You
        may not modify or alter the Content in any way. We reserve all rights
        not expressly granted to you in and to the Website, the Content and the
        Marks.
      </p>
      <p>
        <strong>Prohibited Uses</strong>
      </p>
      <p>
        You may use the Website only for lawful purposes and in accordance with
        these Terms of Use. You agree not to use the Website:
      </p>
      <ul>
        <li>
          In any way that violates any applicable federal, state, local, or
          international law or regulation (including, without limitation, any
          laws regarding the export of data or software to and from the United
          States or other countries);
        </li>
        <li>
          For the purpose of exploiting, harming, or attempting to exploit or
          harm minors in any way by exposing them to inappropriate content,
          asking for personally identifiable information or otherwise;
        </li>
        <li>
          To send, knowingly receive, upload, download, use, or re-use any
          material which does not comply with these Terms of Use;
        </li>
        <li>
          To transmit, or procure the sending of, any advertising or promotional
          material without our prior written consent, including any "junk mail",
          "chain letter", "spam", or any other similar solicitation;
        </li>
        <li>
          To impersonate or attempt to impersonate Beam, a contractor of Beam,
          another user, or any other person or entity (including, without
          limitation, by using email addresses or screen names associated with
          any of the foregoing); and
        </li>
        <li>
          To engage in any other conduct that restricts or inhibits anyone's use
          or enjoyment of the Website, or which, as determined by us, may harm
          Beam or users of the Website or expose them to liability.
        </li>
      </ul>
      <p>Additionally, you agree not to:</p>
      <ul>
        <li>
          Use the Website in any manner that could disable, overburden, damage,
          or impair the Website or interfere with any other party's use of the
          Website, including their ability to engage in real time activities
          through the Website;
        </li>
        <li>
          Use any robot, spider, or other automatic device, process or means to
          access the Website for any purpose, including monitoring or copying
          any of the material on the Website;
        </li>
        <li>
          Use any manual process to monitor or copy any of the material on the
          Website or for any other unauthorized purpose without our prior
          written consent;
        </li>
        <li>
          Use any device, software or routine that interferes with the proper
          working of the Website;
        </li>
        <li>
          Introduce any viruses, trojan horses, worms, logic bombs, or other
          material which is malicious or technologically harmful;
        </li>
        <li>
          Use a VPN or any other tool to gain unauthorized access to, interfere
          with, damage, or disrupt any parts of the Website, the server(s) on
          which the Website is stored, or any server, computer or database
          connected to the Website;
        </li>
        <li>
          Attack the Website via a denial-of-service attack or a distributed
          denial-of-service attack; and
        </li>
        <li>
          Otherwise attempt to interfere with the proper working of the Website.
        </li>
      </ul>
      <p>
        <strong>Reliance on Information Posted</strong>
      </p>
      <p>
        The information presented on or through the Website is made available
        solely for general information purposes. We do not warrant the accuracy,
        completeness or usefulness of this information. Any reliance you place
        on such information is strictly at your own risk. We disclaim all
        liability and responsibility arising from any reliance placed on such
        materials by you or any other visitor to the Website, or by anyone who
        may be informed of any of its contents.
      </p>
      <p>
        The Website may include content provided by third parties, including
        materials provided by third-party licensors, syndicators, aggregators,
        and/or reporting services. All statements and/or opinions expressed in
        these materials, other than the content provided by Beam, are solely the
        opinions and the responsibility of the person or entity providing those
        materials. These materials do not necessarily reflect the opinion of
        Beam. We are not responsible, or liable to you or any third party, for
        the content or accuracy of any materials provided by any third parties.
      </p>
      <p>
        <strong>Changes to the Website</strong>
      </p>
      <p>
        We may update the content on the Website from time to time, but its
        content is not necessarily complete or up-to-date. Any of the material
        on the Website may be out of date at any given time, and we are under no
        obligation to update such material.
      </p>
      <p>
        <strong>Information About You and Your Visits to the Website</strong>
      </p>
      <p>
        All information we collect on the Website and the cookies we use is
        subject to our Privacy Policy. By using the Website, you consent to all
        actions that may be taken by us with respect to your information in
        compliance with the Privacy Policy.
      </p>
      <p>
        <strong>Linking to the Website and Social Media Features</strong>
      </p>
      <p>
        You may link to our homepage, provided you do so in a way that is fair
        and legal and does not damage our reputation or take advantage of it,
        but you must not establish a link in such a way as to suggest any form
        of association, approval or endorsement on our part without our express
        written consent.
      </p>
      <p>
        <strong>Links from the Website</strong>
      </p>
      <p>
        If the Website contain links to other sites and resources provided by
        third parties, these links are provided for your convenience only. This
        includes links contained in advertisements, including banner
        advertisements and sponsored links. We have no control over the contents
        of those sites or resources, and accept no responsibility for them or
        for any loss or damage that may arise from your use of them. If you
        decide to access any of the third party Website linked to the Website,
        you do so entirely at your own risk and subject to the terms and
        conditions of use for such Website. We reserve the right to withdraw
        linking permission without notice.
      </p>
      <p>
        <strong>Geographic Restrictions</strong>
      </p>
      <p>
        The owner of the Website is based in the Cayman Islands. We make no
        claims that the Website or any of its content is accessible or
        appropriate outside of the Cayman Islands. Access to the Website may not
        be legal by certain persons or in certain countries. If you access the
        Website from outside the Cayman Islands, you do so on your own
        initiative and are responsible for compliance with local laws.
      </p>
      <p>
        <strong>Disclaimer of Warranties</strong>
      </p>
      <p>
        You understand that we cannot and do not guarantee or warrant that files
        available for downloading from the internet or the Website will be free
        of viruses or other destructive code. You are responsible for
        implementing sufficient procedures and checkpoints to satisfy your
        particular requirements for anti-virus protection and accuracy of data
        input and output, and for maintaining a means external to our site for
        any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS
        OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR
        OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
        EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO
        YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
        WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY
        WEBSITE LINKED TO IT.
      </p>
      <p>
        YOUR USE OF THE WEBSITE, THEIR CONTENT AND ANY SERVICES OR ITEMS
        OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, THEIR
        CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS
        PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES
        OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER BEAM NOR ANY PERSON
        ASSOCIATED WITH BEAM MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT
        TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
        AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER
        BEAM NOR ANYONE ASSOCIATED WITH BEAM REPRESENTS OR WARRANTS THAT THE
        WEBSITE, THEIR CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
        WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT
        DEFECTS WILL BE CORRECTED, THAT THE WEBSITE OR THE SERVER(S) THAT MAKES
        THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT
        THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL
        OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
      </p>
      <p>
        BEAM HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
        IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
        WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
        PARTICULAR PURPOSE.
      </p>
      <p>
        SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF WARRANTIES OR LIMITATIONS
        ON THE DURATION OF IMPLIED WARRANTIES, SO THE ABOVE DISCLAIMER MAY NOT
        APPLY TO YOU IN THEIR ENTIRETIES, BUT WILL APPLY TO THE MAXIMUM EXTENT
        PERMITTED BY APPLICABLE LAW.
      </p>
      <p>
        <strong>Limitation on Liability</strong>
      </p>
      <p>
        IN NO EVENT WILL BEAM, ITS AFFILIATES OR THEIR LICENSORS, SERVICE
        PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR
        DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
        CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITE
        LINKED TO THEM, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITE OR ANY
        SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITE,
        INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR
        PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN
        AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
        LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
        LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
        OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. THE FOREGOING DOES NOT
        AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
        APPLICABLE LAW WHICH MAY INCLUDE FRAUD.
      </p>
      <p>
        <strong>Indemnification</strong>
      </p>
      <p>
        You agree to defend, indemnify, and hold harmless Beam, its affiliates,
        licensors, and service providers, and its and their respective officers,
        directors, employees, contractors, agents, licensors, suppliers,
        successors, and assigns from and against any claims, liabilities,
        damages, judgments, awards, losses, costs, expenses, or fees (including
        reasonable attorneys' fees) arising out of or relating to your violation
        of these Terms of Use or your use of the Website, including, but not
        limited to, any use of the Website' content, services and products other
        than as expressly authorized in these Terms of Use or your use of any
        information obtained from the Website.
      </p>
      <p>
        <strong>Governing Law and Jurisdiction</strong>
      </p>
      <p>
        All matters relating to the Website and these Terms of Use and any
        dispute or claim arising therefrom or related thereto (in each case,
        including non-contractual disputes or claims), shall be governed by and
        construed in accordance with the internal laws of the Cayman Islands
        without giving effect to any choice or conflict of law provision or rule
        (whether of the Cayman Islands or any other jurisdiction).
      </p>
      <p>
        Any dispute arising out of or in connection with the Website and these
        Terms of Use, including any question regarding its existence, validity
        or termination, shall be referred to and finally resolved by arbitration
        under the rules of the London Court of International Arbitration
        ("LCIA"), which rules are deemed to be incorporated by reference into
        this clause. The number of arbitrators shall be one. The seat, or legal
        place, of arbitration shall be London, United Kingdom. The language to
        be used in the arbitration shall be English. You and Beam agree to
        submit all Disputes between you and Beam to individual binding
        arbitration. "Dispute" means any dispute, claim, or controversy between
        you and Beam that relates to the Website and these Terms of Use.
      </p>
      <p>
        If a Dispute must be arbitrated, you or Beam must start arbitration of
        the Dispute within one (1) year from when the Dispute first arose. If
        applicable law requires you to bring a claim for a Dispute sooner than
        one (1) year after the Dispute first arose, you must start arbitration
        in that earlier time period. Beam encourages you to tell us about a
        Dispute as soon as possible so we can work to resolve it. The failure to
        provide timely notice will bar all claims.
      </p>
      <p>
        In any Dispute, the arbitrator will award to the prevailing party, if
        any, the costs and attorneys' fees reasonably incurred by the prevailing
        party in connection with those aspects of its claims or defenses on
        which it prevails, and any opposing awards of costs and legal fees
        awards will be offset.
      </p>
      <p>
        Any breach by you of these Terms of Use could cause Beam irreparable
        harm for which it has no adequate remedies at law. Accordingly, Beam is
        entitled to seek specific performance or injunctive relief for any such
        breach. Nothing in this section will preclude Beam from seeking specific
        performance or injunctive relief from a court of appropriate
        jurisdiction.
      </p>
      <p>
        <strong>Waiver and Severability</strong>
      </p>
      <p>
        No waiver by Beam of any term or condition set forth in these Terms of
        Use shall be deemed a further or continuing waiver of such term or
        condition or a waiver of any other term or condition, and any failure of
        Beam to assert a right or provision under these Terms of Use shall not
        constitute a waiver of such right or provision.
      </p>
      <p>
        If any provision of these Terms of Use is held by a court or other
        tribunal of competent jurisdiction to be invalid, illegal, or
        unenforceable for any reason, such provision shall be eliminated or
        limited to the minimum extent such that the remaining provisions of the
        Terms of Use will continue in full force and effect.
      </p>
      <p>
        <strong>Entire Agreement</strong>
      </p>
      <p>
        The Terms of Use, our Privacy Policy and other terms and conditions
        applicable at the time you access the Website constitute the sole and
        entire agreement between you and Beam with respect to the Website and
        supersede all prior and contemporaneous understandings, agreements,
        representations and warranties, both written and oral, with respect to
        the Website.
      </p>
    </pre>
  );
};
