import React from "react";

interface Props {
  className?: string;
}

export const PrivacyIcon = (props: Props) => {
  return (
    <svg
      height="39"
      width="35"
      fill="none"
      viewBox="0 0 35 39"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M17.7971 14.488H15.0491C14.8211 14.488 14.7611 14.572 14.7611 14.644C14.7611 14.752 14.8091 14.824 15.0371 14.824C15.6971 14.824 15.7211 15.076 15.7211 15.592V23.92H16.4891V14.92H17.1971V23.92H17.9531V19.324C19.5611 19.3 20.3051 18.088 20.3051 16.876C20.3051 15.7 19.4051 14.488 17.7971 14.488Z"
          fill="#F1F1F1"
        />
      </g>
      <defs />
    </svg>
  );
};
