import React from "react";

interface Props {
  className?: string;
}

export const QuestionMarkIcon = (props: Props) => {
  return (
    <svg
      height="42"
      width="34"
      fill="none"
      viewBox="0 0 34 42"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M14.8252 16.188V16.17C14.8252 15.972 14.8432 15.756 14.8972 15.63C15.0412 15.144 15.4192 15 16.3012 15C17.6512 15 18.8932 16.17 18.8932 17.988C18.8932 19.176 18.4612 19.878 17.8492 20.58C17.4892 21.012 17.0392 21.426 16.6252 21.948C16.3552 22.29 16.2832 22.344 16.2472 22.65C16.2112 23.568 16.1932 23.766 15.9772 23.766C15.7972 23.766 15.7252 23.64 15.6712 23.316C15.6172 22.902 15.5632 22.29 15.5632 21.408C15.5632 20.706 15.6712 20.598 15.9232 20.328C16.9852 19.176 17.7772 18.24 17.7772 17.232C17.7772 16.26 17.2912 15.576 16.7332 15.576C16.2652 15.576 16.0312 16.098 15.8512 16.602C15.6712 17.088 15.5452 17.574 15.2752 17.574C14.9332 17.574 14.8252 17.358 14.8252 16.188ZM16.0132 26.754H15.9952C15.4732 26.754 15.1492 26.25 15.1492 25.728C15.1492 25.224 15.4732 24.72 16.0132 24.72C16.5532 24.72 16.8952 25.242 16.8952 25.728C16.8952 26.25 16.5532 26.754 16.0132 26.754Z"
          fill="#F1F1F1"
        />
      </g>
      <defs />
    </svg>
  );
};
