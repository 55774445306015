import React from "react";
import styles from "./styles.module.css";

interface Props {
  children: React.ReactNode;
  icon: React.ReactNode;
}

export const MenuItem = ({ children, icon, ...rest }: Props) => {
  return (
    <div className={styles.container} {...rest}>
      <div className={styles.icon}>{icon}</div>

      {children}
    </div>
  );
};
