import React from "react";

interface Props {
  className?: string;
}

export const StatusIcon = (props: Props) => {
  return (
    <svg
      height="40"
      width="41"
      fill="none"
      viewBox="0 0 41 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M26.4619 15.276V24.996C26.4619 25.224 26.4259 25.272 26.1979 25.272C25.8859 25.272 25.5859 25.248 25.2859 25.248C24.9739 25.248 24.6859 25.272 24.3739 25.272C24.1459 25.272 24.0859 25.2 24.0859 25.056C24.0859 24.888 24.1579 24.84 24.3859 24.84C24.6979 24.84 25.0099 24.852 25.3219 24.852C25.4779 24.852 25.5499 24.78 25.5499 24.612V15.66C25.5499 15.48 25.4779 15.42 25.3219 15.42C25.0099 15.42 24.6979 15.432 24.3859 15.432C24.1579 15.432 24.0859 15.384 24.0859 15.204C24.0859 15.072 24.1459 15 24.3739 15C24.6859 15 24.9739 15.024 25.2859 15.024C25.5859 15.024 25.8859 15 26.1979 15C26.4259 15 26.4619 15.048 26.4619 15.276Z"
          fill="#F1F1F1"
        />
      </g>
      <g>
        <path
          d="M18.1626 21.276L17.6826 20.748C17.9826 20.22 18.4266 19.704 19.2186 19.704C19.6626 19.704 20.1306 19.896 20.5746 20.1C21.0066 20.292 21.4506 20.52 21.7866 20.52C22.2666 20.52 22.5786 20.1 22.8426 19.692L23.3226 20.208C22.9506 20.736 22.5426 21.252 21.7866 21.252C21.3306 21.252 20.9466 21.06 20.5386 20.844C20.1546 20.64 19.7226 20.436 19.1706 20.436C18.6666 20.436 18.3666 20.856 18.1626 21.276Z"
          fill="#F1F1F1"
        />
      </g>
      <g>
        <path
          d="M14.542 24.996V15.276C14.542 15.048 14.59 15 14.818 15C15.118 15 15.418 15.024 15.718 15.024C16.03 15.024 16.33 15 16.63 15C16.87 15 16.93 15.072 16.93 15.216C16.93 15.384 16.858 15.432 16.618 15.432C16.306 15.432 16.006 15.42 15.682 15.42C15.526 15.42 15.454 15.492 15.454 15.66V24.612C15.454 24.792 15.538 24.852 15.682 24.852C16.006 24.852 16.306 24.84 16.618 24.84C16.858 24.84 16.93 24.888 16.93 25.068C16.93 25.2 16.87 25.272 16.63 25.272C16.33 25.272 16.03 25.248 15.718 25.248C15.418 25.248 15.118 25.272 14.818 25.272C14.59 25.272 14.542 25.224 14.542 24.996Z"
          fill="#F1F1F1"
        />
      </g>
      <defs />
    </svg>
  );
};
