// P5Sketch.js
import React, { useEffect, useRef } from 'react';
import p5 from 'p5';

const SnakeGame = () => {
  const sketchRef = useRef();

  useEffect(() => {
    const sketch = (p) => {
      // Your p5.js code goes here, for example:
      p.setup = () => {
        p.createCanvas(400, 200);
        p.background(200);
      };

      p.draw = () => {
        p.fill(255, 0, 0);
        p.ellipse(50, 50, 50, 50);
      };
    };

    new p5(sketch, sketchRef.current);

    // Optional: Return a cleanup function to stop the p5.js sketch when the component unmounts
    return () => {
      
    };
  }, []);

  return <div ref={sketchRef}></div>;
};

export default SnakeGame;
