import React, { useState, useEffect } from "react";

const MenuBar = ({ name }) => {
  const [userName, setName] = useState("User");
  const [localTime, setLocalTime] = useState("");
  const [tokyoTime, setTokyoTime] = useState("");
  const [ethPrice, setEthPrice] = useState("");

  useEffect(() => {
    const storedName = localStorage.getItem("userName");
    if (storedName) {
      setName(storedName);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const local = new Date().toLocaleTimeString("en-US");
      const tokyo = new Date().toLocaleTimeString("en-US", {
        timeZone: "Asia/Tokyo",
      });
      setLocalTime(local);
      setTokyoTime(tokyo);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchEthPrice = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=beam-2&vs_currencies=usd"
        );
        const data = await response.json();
        setEthPrice(data["beam-2"].usd.toFixed(4));
      } catch (error) {
        console.error("Error fetching MC price:", error);
      }
    };

    fetchEthPrice();
    const interval = setInterval(fetchEthPrice, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="menu-bar">
      Hello, {name}
      <div className="right-section">
        {localTime} |{" "}
        <a
          href="https://www.coingecko.com/en/coins/beam-2"
          target="_blank"
          rel="noreferrer"
        >
          BEAM: ${ethPrice}
        </a>
      </div>
    </div>
  );
};

export default MenuBar;
