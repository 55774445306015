// FauxWindow.js
import React, { useRef, useEffect } from "react";
import CloseIcon from "../Images/close-icon.svg";

function FauxWindow({ onClose, children, height }) {
  const windowRef = useRef(null);

  const handleDragStart = (e) => {
    const rect = windowRef.current.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;

    const handleDrag = (moveEvent) => {
      windowRef.current.style.left = `${moveEvent.clientX - offsetX}px`;
      windowRef.current.style.top = `${moveEvent.clientY - offsetY}px`;
    };

    const handleDragEnd = () => {
      document.removeEventListener("mousemove", handleDrag);
      document.removeEventListener("mouseup", handleDragEnd);
    };

    document.addEventListener("mousemove", handleDrag);
    document.addEventListener("mouseup", handleDragEnd);
  };

  useEffect(() => {
    if (windowRef.current) {
      const maxWidth = window.innerWidth - 400; // Assuming 400px is the default width of your window.
      const maxHeight = window.innerHeight - (height || 330); // 330px = default height + toolbar height.

      const randomX = Math.floor(Math.random() * maxWidth);
      const randomY = Math.floor(Math.random() * maxHeight);

      windowRef.current.style.left = `${randomX}px`;
      windowRef.current.style.top = `${randomY}px`;
    }
  }, []);

  return (
    <div className="faux-window" ref={windowRef} style={height && { height }}>
      <div className="faux-window-toolbar" onMouseDown={handleDragStart}>
        <div></div>
        <button className="close-button" onClick={onClose}>
          <img src={CloseIcon} />
        </button>
      </div>
      <div className="faux-window-content">{children}</div>
    </div>
  );
}

export default FauxWindow;
