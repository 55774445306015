import React from 'react';

export const Privacy = () => {
  return (
    <pre
      style={{
        maxWidth: '800px',
        whiteSpace: 'pre-wrap',
      }}
    >
      <p>
        <strong>PRIVACY POLICY</strong>
      </p>
      <p>
        <strong>Last Updated: 27 September 2024</strong>
      </p>

      <p>
        Beam Interface, a Cayman Islands exempted company (including all
        affiliates, collectively referred to as "Beam", "we", "us" or "our"),
        currently provides certain information about the Beam ecosystem (a
        gaming ecosystem consisting of technologies, projects, communities,
        organizations and persons, utilizing, relying on, securing, or
        developing (as applicable) the Beam network, protocols and applications
        deployed on Beam network and their related smart contract systems), as
        well as related content and functionality about Beam through the website
        located at https://www.onbeam.com or any sub-URL of such a website (the
        "Website").
      </p>
      <p>
        This privacy policy ("Privacy Policy") applies to all Personal Data
        (defined below) collected through the Website and cookies used, as well
        as marketing campaigns, product feedback forms, surveys, events, and
        sales. We collect and use your Personal Data in accordance with this
        Privacy Policy and in compliance with applicable data protection
        legislation, including but not limited to the Cayman Islands' Data
        Protection Act, 2021 ("DPA"), EU General Data Protection Regulation
        ((EU) 2016/679) ("GDPR"), EU Privacy and Electronic Communications
        Directive (2002/58/EC), and the California Consumer Privacy Act (Cal.
        Civ. Code § 1798.100 et seq.).
      </p>
      <p>
        <u>Personal Data</u>
      </p>
      <p>
        "Personal Data" or "Personal Information" refers to any personally
        identifiable information that can be used to identify or contact you,
        which may include, but is not limited to:
      </p>
      <ul>
        <li>Name</li>
        <li>Date of birth</li>
        <li>Email</li>
        <li>Phone number</li>
        <li>Mailing address</li>
        <li>IP address</li>
        <li>Wallet ID and associated metadata</li>
        <li>Usage data</li>
      </ul>

      <p>
        <u>Information Obtained</u>
      </p>
      <p>
        When you visit the Website, we and Beam's service providers may obtain
        or request information about you, your computer or mobile device, and
        your interaction over time with the Website, as described below.
      </p>
      <p>
        <strong>Personal information that is automatically collected</strong>.
        When you visit the Website, we and Beam's service providers may
        automatically log information about you, your computer or mobile device,
        and your interaction over time with the Website, our communications and
        other online services, such as:
      </p>
      <ul>
        <li>
          Device data, such as your computer's or mobile device's operating
          system type and version, manufacturer and model, browser type, screen
          resolution, RAM and disk size, CPU usage, device type (e.g., phone,
          tablet), IP address, unique identifiers (including identifiers used
          for advertising purposes), language settings, mobile device carrier,
          radio/network information (e.g., WiFi, LTE, 5G), and general location
          information such as city, state or geographic area when you access the
          Website.
        </li>
        <li>
          Online activity data, such as pages or screens you viewed on the
          Website, how long you spent on a page or screen, navigation paths
          between pages or screens, information about your activity on a page or
          screen, access times, and duration of access, and whether you have
          opened our marketing emails or clicked links within them.
        </li>
      </ul>
      <p>
        <strong>Personal information that you provide to us</strong>. When you
        visit the Website, you may be asked to provide the following information
        to us:
      </p>
      <li>
        Contact and account information, such as your first and last name, email
        address and other contact details.
      </li>
      <li>
        Feedback or correspondence, such as information you provide when you
        contact us with questions, feedback, product reviews, or otherwise
        correspond with us online.
      </li>
      <li>
        Usage information, such as information about how you use the Website and
        interact with them, including information associated with any content
        you upload to the Website or otherwise submit to us, and information you
        provide when you use any interactive features of the Website.
      </li>
      <li>
        Marketing information, such as your preferences for receiving
        communications about our activities, events, and publications, and
        details about how you engage with our communications.
      </li>

      <p>
        <strong>Personal information that we obtain from third parties</strong>.
        When you visit the Website, we may obtain information from the following
        sources:
      </p>
      <ul>
        <li>
          Social media information. We may use plug-ins from social networks on
          the Website and/or maintain pages on social platforms, such as
          Telegram, Discord, and other third-party platforms. When you activate
          plug-ins by clicking on them, the operators of the respective social
          platforms may record that you are on the Website and may use this
          information. Additionally, when you visit or interact with
          Website-related pages on those social media platforms, the platform
          provider's privacy policy will apply to your interactions and their
          collection, use and processing of your personal information. You or
          the platforms may provide us with information through the platform,
          and we will treat such information in accordance with this Privacy
          Policy. Beam is not responsible for data collected by these individual
          social media platforms, and any processing of your personal data by
          social media platforms is solely their responsibility and occurs
          according to their privacy policies. Please check with them regarding
          their privacy policies.
        </li>
        <li>
          Third-party login information. When you link, connect, or login to the
          Website with a third-party service (e.g., Google, Facebook, or Apple),
          you direct the service to send us information such as your information
          as controlled by that service or as authorized by you via your privacy
          settings at that service.
        </li>
        <li>
          Cryptocurrency wallets. Where the use of a cryptocurrency wallet is
          used to access the Website, we may obtain information from the wallet
          related to your wallet address and transactions.
        </li>
        <li>
          Other sources. We may obtain your personal information from other
          third parties, such as marketing partners, publicly available sources
          and data providers.
        </li>
      </ul>
      <p>
        <strong>Blockchain transaction data</strong>. The nature of a public
        blockchain means that certain information is publicly available,
        including but not limited to: your wallet address; the address of a
        sender initiating a transaction; the address of a recipient; the maximum
        amount of gas fees that the sender is willing to allocate for executing
        the transaction; the price the sender is willing to pay per unit of gas;
        the nonce (a sequential number issued by the sender's address); the
        cryptographic signature generated using the sender's private key; the IP
        address from the requester (visible only to remote procedure call
        nodes); and any additional data needed for the transaction, such as
        invoking functions in a smart contract or providing arguments for those
        functions. When you authorize (i.e., use a crypto wallet to "sign") a
        blockchain transaction through any of the Website, you are authorizing
        us to collect and use all information associated with that transaction
        which we will do in accordance with this Privacy Policy. Note that we
        are not able to control whether or how third parties use information
        that is stored on the blockchain, and we expressly disclaim
        responsibility for any such activities by third parties.
      </p>
      <p>
        <u>Cookies and Automatic Data Collection</u>
      </p>
      <p>
        We use services provided by Google that use cookies to collect
        information about your use of the services and our interactions with
        you, for the purposes of recognizing your web browser and tell us how
        and when you visit and use our services, to analyze trends, learn about
        our user base and operate and improve our services. Cookies are small
        pieces of data - usually text files - placed on your computer, tablet,
        phone or similar device when you use that device to access our services.
        You can opt out of having your online activity and device data collected
        through these third-party services, including by:
      </p>
      <ul>
        <li>
          Blocking cookies in your browser by following the instructions in your
          browser settings. For more information about cookies, including how to
          see the cookies on your device, manage them, and delete them, visit{' '}
          <a href='http://www.allaboutcookies.org/'>www.allaboutcookies.org</a>.
        </li>
        <li>
          Blocking or limiting the use of your advertising ID on your mobile
          device through the device settings.
        </li>
        <li>
          Using privacy plug-ins or browsers. Certain browsers and browser
          extensions can be configured to block third-party cookies and
          trackers.
        </li>
        <li>
          Using the platform opt-out provided by Google at{' '}
          <a href='https://adssettings.google.com/'>
            https://adssettings.google.com
          </a>
          . You can learn more about how Google uses your information by
          reviewing Google's privacy policy at{' '}
          <a href='https://policies.google.com/privacy'>
            https://policies.google.com/privacy
          </a>
          .
        </li>
        <li>
          Using advertising industry opt-out tools on each device or browser
          where you use the Services, available at{' '}
          <a href='http://optout.aboutads.info/'>http://optout.aboutads.info</a>{' '}
          and{' '}
          <a href='http://optout.networkadvertising.org/'>
            http://optout.networkadvertising.org
          </a>
          .
        </li>
      </ul>

      <p>
        We also use the following other techniques to automatically collect
        information through the Website:
      </p>
      <ul>
        <li>
          Local storage technologies, like HTML5, provide cookie-equivalent
          functionality but can store larger amounts of data, including on your
          device outside of your browser in connection with specific
          applications.
        </li>
        <li>
          Web beacons, also known as pixel tags or clear GIFs, are used to
          demonstrate that a webpage or email was accessed or opened, or that
          certain content was viewed or clicked.
        </li>
      </ul>

      <p>
        <u>Use of Personal Information</u>
      </p>
      <p>
        <strong>To operate the Website</strong>. We use your Personal
        Information to: provide, operate, maintain, secure and improve the
        Website; provide information about the Website; communicate with you
        about the Website, including by sending you announcements, updates,
        security alerts, and support and administrative messages; understand
        your needs and interests, and personalize your experience; and respond
        to your requests, questions and feedback and to provide support. We use
        this information to perform our obligations to you or when it is in our
        legitimate business interests to do so.
      </p>
      <p>
        <strong>For research and development</strong>. It is in our legitimate
        business interests to use your Personal Information to analyze and
        improve the Website and to develop new products and services, including
        by studying use of the Website.
      </p>
      <p>
        <strong>To conduct surveys</strong>. It is in our legitimate interest to
        conduct surveys and collect feedback from you.
      </p>
      <p>
        <strong>Marketing and advertising</strong>. Except where consent is
        required, and unless you have opted out of receiving marketing
        communications, it is in our and our marketing partners' legitimate
        interests to collect and use your Personal Information for marketing and
        advertising purposes. We or our advertising partners may from
        time-to-time send you direct marketing communications as permitted by
        law, including, but not limited to, notifying you of special promotions,
        offers and events via email. You may opt out of our marketing
        communications as described in the "Opt-out of marketing communications"
        section below.
      </p>
      <p>
        <strong>For compliance, fraud prevention, and safety</strong>. We use
        your Personal Information as may be necessary to comply with applicable
        laws, lawful requests, and legal process, such as to respond to
        subpoenas or requests from government authorities. We use your Personal
        Information to: (a) protect our, your or others' rights, privacy, safety
        or property (including by making and defending legal claims); (b)
        enforce the terms and conditions that govern the Website; and (c)
        protect, investigate and deter against fraudulent, harmful,
        unauthorized, unethical or illegal activity. In these circumstances, we
        will process your Personal Information to either comply with a legal
        obligation or when it is in our legitimate business interests.
        Furthermore, the IP addresses may be evaluated, together with other
        data, in case of attacks on the network infrastructure or other
        unauthorized use or misuse of the Website, for the purpose of
        intelligence and protection, and if appropriate, used in criminal
        proceedings for identification and civil and criminal proceedings
        against the relevant users. This is our legitimate interest in the
        processing of data in the sense of Art. 6 Par. 1 lit. f GDPR.
      </p>
      <p>
        <strong>To create anonymous data</strong>. It is in our legitimate
        business interests to create anonymous data from Personal Information
        collected by removing information that makes the data personally
        identifiable to you. We may use this anonymous data and share it with
        third parties for our lawful business purposes, including to analyze and
        improve the Website and promote our business.
      </p>
      <p>
        <u>Sharing of Personal Information</u>
      </p>
      <p>
        With the exception of the provider(s) of the Website, we do not make
        your Personal Data available to third parties unless you have expressly
        consented to it, if we are legally obligated to, or if this is necessary
        to enforce our rights concerning a contractual relationship. This is our
        legitimate interest in the processing of data in the sense of Art. 6
        Par. 1 lit. f GDPR.
      </p>
      <p>
        <strong>Service providers</strong>. We may share your Personal
        Information with third party companies and individuals that provide
        services on our behalf or help us operate the Website (such as customer
        support, hosting, data processors, analytics, email delivery, marketing,
        identity verification, and database management services).
      </p>
      <p>
        <strong>Web3 projects and collaborators</strong>. We may share your
        Personal Information with web3 projects and collaborators as necessary
        to provide the Website.
      </p>
      <p>
        <strong>Professional advisors</strong>. We may disclose your Personal
        Information to professional advisors, such as lawyers and auditors,
        where necessary in the course of the professional services that they
        render to us.
      </p>
      <p>
        <strong>For compliance, fraud prevention and safety</strong>. We may
        share your Personal information for compliance, fraud prevention, and
        safety purposes described above.
      </p>
      <p>
        <strong>Financial transactions</strong>. If you conduct financial
        transactions by credit card or debit card through the Website, we may
        forward your credit/debit card information to the credit/debit card
        issuer and the credit/debit card acquirer. If you choose to use a
        credit/debit card, you may be asked to provide all the necessary
        information. The legal basis for passing on the data lies in the
        fulfillment of an agreement in the sense of Art. 6 Par. Lit. b GDPR.
      </p>
      <p>
        <u>Your Rights and Choices</u>
      </p>
      <p>
        <strong>Opt out of marketing communications</strong>. You may opt out of
        marketing-related emails by following the opt-out or unsubscribe
        instructions at the bottom of the email. You may continue to receive
        service-related and other non-marketing emails.
      </p>
      <p>
        <strong>Data protection rights</strong>. Depending on where you live,
        you may have the following rights, as provided under applicable law and
        subject to any limitations in such law:
      </p>
      <ul>
        <li>
          To access the Personal information we hold about you (including, if
          applicable, to receive it in a structured and commonly used
          machine-readable format);
        </li>
        <li>
          To request we correct any inaccurate Personal Data we hold about you;
        </li>
        <li>
          To request we delete any Personal Information we hold about you;
        </li>
        <li>To restrict the processing of Personal Data we hold about you;</li>
        <li>
          To object to the processing of Personal Information we hold about you;
          and/or
        </li>
        <li>
          To withdraw your consent to the processing of Personal Data we hold
          about you, when we have relied on consent as the legal basis to
          process your Personal Information.
        </li>
      </ul>
      <p>
        Please note that, prior to any response to the exercise of such rights,
        we may require you to verify your identity. In addition, we may have
        valid legal reasons to refuse your request and will inform you if that
        is the case. For more information on your rights, please contact us
        using the details in the "How to contact us" section below.
      </p>
      <p>
        Please note that part of the Website incorporates blockchain technology.
        A blockchain is a shared and synchronized digital database that is
        stored on multiple nodes (computers that store a local version of the
        database). As, by design, data on a blockchain cannot be changed or
        deleted, your ability to exercise your data protection rights such as
        your right to erasure, or your rights to object or restrict processing
        with respect to onchain Personal Data may be affected.
      </p>
      <p>
        <u>International Data Transfers</u>
      </p>
      <p>
        By using the Website, you understand and acknowledge that we may
        transfer your Personal Information to service providers or other third
        parties who are located in countries which may not provide the same
        protections as the data protection laws where you are based. This
        includes service providers of the Website and e-commerce providers such
        as payment solution providers to assist us in the processing of your
        online payments. When we transfer your Personal Data to third parties
        abroad for the purposes of the data processing described in this Privacy
        Policy, unless we can rely on a derogation provided under data
        protection law, we will ensure that relevant safeguards are in place to
        afford adequate protection for your Personal Information and we will
        comply with applicable data protection laws, in particular if you reside
        in (i) the British Virgin Islands, UK or the EEA by relying on a UK
        government adequacy regulation or adequacy decision by the European
        Commission, (ii) the United States, by relying the the California
        Consumer Privacy Act, or (iii) other jurisdictions, by relying on
        contractual protections for the transfer of your Personal Information.
        For more information about how we transfer Personal Data
        internationally, please contact us as set out in the "How to contact us"
        section below.
      </p>
      <p>
        The California Consumer Privacy Act or "CCPA" (Cal. Civ. Code § 1798.100
        et seq.) affords consumers residing in California certain rights with
        respect to their personal information. If you are a California resident,
        this section applies to you.
      </p>
      <p>
        <u>Notice to California Residents</u>
      </p>
      <p>
        California Civil Code Section 1798.83 permits individual California
        residents to request certain information regarding our disclosure of
        certain categories of personal information to third parties for those
        third parties' direct marketing purposes. To make such a request, please
        contact us using the information in the "How to Contact Us" section
        below. This request may be made no more than once per calendar year.
      </p>
      <p>
        If you are a California resident, you have certain additional rights
        with respect to your personal information pursuant to the CCPA.
      </p>
      <p>We are required to inform you of:</p>
      <ul>
        <li>
          What categories of information we may collect about you, including
          during the preceding 12 months: See the section above "Information
          Obtained".
        </li>
        <li>
          The purposes for which we may use your personal information, including
          during the preceding 12 months: See the section above "Use of Personal
          Information".
        </li>
        <li>
          The purposes for which we may share your personal information,
          including during the preceding 12 months: See the section above
          "Sharing of Personal Information". In addition, we may share the
          following categories of information with the following parties:
        </li>
        <ul>
          <li>
            Identity information: Service providers such as KYC service
            providers.
          </li>
          <li>Contact information: Marketing partners and event partners.</li>
          <li>
            Financial information: Service providers such as payment service
            providers and logistics providers.
          </li>
          <li>
            In the preceding 12 months, we have not sold any personal
            information of consumers.
          </li>
        </ul>
      </ul>
      <p>
        You have the right to request to know: (i) the categories of personal
        information we have collected about you in the last 12 months; (ii) the
        specific pieces of personal information we have about you; (iii) the
        categories of sources from which that personal information was
        collected; (iv) the categories of your personal information that we sold
        or disclosed in the last 12 months; (v) the categories of third parties
        to whom your personal information was sold or disclosed in the last 12
        months; and (vi) the purpose for collecting and selling your personal
        information. These rights are subject to limitations as described in the
        CCPA. We may deny your request if we need to do so to comply with our
        legal rights or obligations.
      </p>
      <p>
        We will not discriminate against any consumer for exercising their CCPA
        rights.
      </p>
      <p>
        You may exercise these rights yourself or you may designate an
        authorized agent to make these requests on your behalf. To protect your
        information, we may need to verify your identity before processing your
        request, including by collecting additional information to verify your
        identity, such as government issued identification documents. We will
        not fulfill your request unless you have provided sufficient information
        for us to reasonably verify you are the individual about whom we
        collected personal information. We will only use the personal
        information provided in the verification process to verify your identity
        or authority to make a request and to track and document request
        responses, unless you initially provided the information for another
        purpose. When we verify your agent's request, we may verify your
        identity and request a signed document from your agent that authorizes
        your agent to make the request on your behalf. To protect your personal
        information, we reserve the right to deny a request from an agent that
        does not submit proof that they have been authorized by you to act on
        their behalf.
      </p>
      <p>
        If you would like to exercise any of these rights, please contact us at
        the email in the "How to Contact Us" section below.
      </p>
      <p>
        <u>Other Sites, Applications and Services</u>
      </p>
      <p>
        The Website may contain links to other websites, mobile applications,
        blockchain protocols, blockchain applications, blockchain exchanges and
        other online and blockchain services (collectively, "Third Party
        Resources") operated by third parties. These links are not an
        endorsement of, or representation that we are affiliated with, any third
        party. In addition, our content may be included in Third Party Resources
        that are not associated with us. We do not control Third Party
        Resources, and we are not responsible for their actions. Other websites
        and services follow different rules regarding the collection, use and
        sharing of your Personal Information. We encourage you to read the
        privacy policies of the Third-Party Resources you use.
      </p>
      <p>
        <u>Security Practices</u>
      </p>
      <p>
        We take precautions to protect your stored Personal Data against
        manipulation, partial or complete loss, and unauthorized access by third
        parties of Personal Information we maintain. Unfortunately, data
        transmission over the internet (including via blockchain) cannot be
        guaranteed as completely secure. Please note that any data transmission
        on the internet (including through blockchains) is generally not secure
        or may be accessed by third parties, and we accept no liability for data
        transmitted to us via the internet or through a blockchain.
      </p>
      <p>
        <u>Children</u>
      </p>
      <p>
        The Website is not intended for use by children under 13 years of age.
        If we learn that we have collected Personal Information through the
        Website from a child under 13 without the consent of the child's parent
        or guardian as required by law, we will delete it.
      </p>
      <p>
        <u>Changes to this Privacy Policy</u>
      </p>
      <p>
        We reserve the right to modify this Privacy Policy at any time. If we
        make material changes to this Privacy Policy, we will notify you by
        updating the date of this Privacy Policy and posting it on the Website.
        We may also provide notification of changes in another way that we
        believe is reasonably likely to reach you, such as via e-mail (if you
        have an account where we have your contact information) or another
        manner through the Website.
      </p>
      <p>
        Any modifications to this Privacy Policy will be effective upon our
        posting the new terms and/or upon implementation of the new changes on
        the Website (or as otherwise indicated at the time of posting).
      </p>
      <p>How to Contact Us</p>
      <p>
        If you have a concern about our privacy practices, including the way we
        handle your Personal Information, please contact us at:{' '}
        <a href='mailto:legal@onbeam.com'>legal@onbeam.com</a>. We will endeavor
        to respond to your complaint as soon as possible. You can also report it
        to your local data protection authority that is authorized to hear those
        concerns. Contact details for certain data protection authorities can be
        found using the links below:
      </p>
      <ul>
        <li>
          For individuals in the EEA:{' '}
          <a href='https://edpb.europa.eu/about-edpb/board/members_en'>
            https://edpb.europa.eu/about-edpb/board/members_en
          </a>
        </li>
        <li>
          For individuals in Cayman Islands:{' '}
          <a href='https://ombudsman.ky/data-protection'>
            https://ombudsman.ky/data-protection
          </a>
        </li>
        <li>
          For individuals in the UK:{' '}
          <a href='https://ico.org.uk/global/contact-us/'>
            https://ico.org.uk/global/contact-us/
          </a>
        </li>
        <li>
          For individuals in Switzerland:{' '}
          <a href='https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html'>
            https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html
          </a>
        </li>
        <li>
          For individuals in California:{' '}
          <a href='https://cppa.ca.gov/about_us/contact.html'>
            https://cppa.ca.gov/about_us/contact.html
          </a>
        </li>
      </ul>
    </pre>
  );
};
