import React from "react";

export const About = () => {
  const content = [
    "----------------------------------------------------------------------------------------",
    "beam – a new era of gaming",
    "----------------------------------------------------------------------------------------",
    "\n",
    "L1: avalanche",
    "subnet: beam",
    "status: online",
    "\n",
    "----------------------------------------------------------------------------------------",
    "\n",
    "**Beam Network: Redefining the Gaming Landscape**",
    "\n",
    "Explore the Beam Network, where gaming transcends traditional boundaries, offering an enriched experience for both players and developers.",
    "\n",
    "**What's Beam All About?**",
    "Beam isn't your average blockchain. It stands out as a holistic gaming ecosystem, built to magnify the joys of gaming. While it's rooted in rigorous testing with select developers, its wide array of features make it a universal platform for everyone.",
    "\n",
    "----------------------------------------------------------------------------------------",
    "\n",
    "**Pillars of Beam:**",
    "\n",
    "1. **Effortless Onboarding:** Teaming up with Openfort, Beam introduces a hassle-free entry for gamers. It ensures quick and simple wallet setups, letting players dive straight into the action.",
    "\n",
    "2. **Beam Companion App:** Think of this as your gaming assistant in the digital realm. Manage, transfer, and secure your in-game assets on-the-go.",
    "   - **iOS:**",
    "   - **Android:**",
    "\n",
    "3. **Sphere - The NFT Gateway:** Sphere is more than a marketplace; it's the nerve center for gaming NFTs, streamlining buying, selling, and management of game assets.",
    "\n",
    "4. **Beam Hub Experience:** Evolving from a simple gaming platform, Beam Hub now offers a dual experience, catering to both newbie explorers and competitive gaming aficionados.",
    "\n",
    "5. **BeamOS - The Central Nexus:** Imagine all of Beam’s offerings meshed into a singular, integrated experience. BeamOS does just that, providing a unified gaming interface.",
    "\n",
    "6. **Developer's Delight - Beam SDK:** The Beam SDK is a treasure trove for developers. It's designed to amplify gameplay, offering a multitude of tools for a tailored gaming development journey.",
    "\n",
    "----------------------------------------------------------------------------------------",
    "\n",
    "**Hand-in-Hand with Avalanche:** While Beam is a standalone marvel, our foundational steps were taken alongside Avalanche. Their cutting-edge tech has been a cornerstone for Beam's innovation.",
    "\n",
    "**Vision for Tomorrow:** At its core, Beam aspires to create harmony between gamers and developers. It's not just about gaming; it's about ownership, empowerment, and a global community. Every feature, every tool, is a step towards this visionary future.",
  ];

  return (
    <>
      {content.map((line, index) => (
        <div key={index}>{line}</div>
      ))}
    </>
  );
};
