import { RefObject, useEffect } from 'react';

export const useCloseOnOutsideClick = (onClose: () => void, refs: RefObject<HTMLElement>[]) => {
  const handleOutsideClick = (e: Event) => {
    e.stopPropagation();

    const clickIsOnRef = refs.some((ref) => ref.current && ref.current.contains(e.target as Node));

    if (clickIsOnRef) {
      return;
    }

    onClose();
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
