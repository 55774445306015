import { ConnectKitButton } from "connectkit";
import React from "react";
import { useIsMounted } from "../hooks/useIsMounted";
import { formatAddress } from "../utils";
import styles from "./ConnectWalletButton.module.css";

interface Props {
  className?: string;
}

export const ConnectWalletButton = ({ className, ...rest }: Props) => {
  const isMounted = useIsMounted();

  return (
    <div {...rest}>
      {isMounted && (
        <ConnectKitButton.Custom>
          {({
            isConnected,
            isConnecting,
            show,
            hide,
            address,
            ensName,
            chain,
          }) => {
            return (() => {
              if (!isConnected) {
                return (
                  <button className={styles.container} onClick={show} disabled>
                    connect (soon)
                  </button>
                );
              }

              return (
                <button className={styles.container} onClick={show}>
                  {ensName || formatAddress(address as `0x${string}`)}
                </button>
              );
            })();
          }}
        </ConnectKitButton.Custom>
      )}
    </div>
  );
};
