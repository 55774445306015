import { Chain } from "wagmi";

export const NETWORKS: Record<number, Chain> = {
  13337: {
    id: 13_337,
    name: "Beam Testnet",
    network: "Beam Testnet",
    nativeCurrency: {
      decimals: 18,
      name: "Merit Circle",
      symbol: "MC",
    },
    rpcUrls: {
      public: {
        http: [
          "https://rpc-bvxa.onbeam.com:9650/ext/bc/y97omoP2cSyEVfdSztQHXD9EnfnVP9YKjZwAxhUfGbLAPYT9t/rpc",
        ],
      },
      default: {
        http: [
          "https://rpc-bvxa.onbeam.com:9650/ext/bc/y97omoP2cSyEVfdSztQHXD9EnfnVP9YKjZwAxhUfGbLAPYT9t/rpc",
        ],
      },
    },
    blockExplorers: {
      default: {
        name: "Explorer",
        url: "https://subnets-test.avax.network/beam",
      },
    },
    contracts: {
      multicall3: {
        address: "0x9BF49b704EE2A095b95c1f2D4EB9010510c41C9E",
        blockCreated: 3,
      },
    },
    testnet: true,
  },
  4337: {
    id: 4337,
    name: "Beam",
    network: "Beam",
    nativeCurrency: {
      decimals: 18,
      name: "Merit Circle",
      symbol: "MC",
    },
    rpcUrls: {
      public: {
        http: ["https://subnets.avax.network/beam/mainnet/rpc"],
      },
      default: {
        http: ["https://subnets.avax.network/beam/mainnet/rpc"],
      },
    },
    blockExplorers: {
      default: {
        name: "Explorer",
        url: "https://subnets.avax.network/beam",
      },
    },
    contracts: {
      multicall3: {
        address: "0x4956f15efdc3dc16645e90cc356eafa65ffc65ec",
        blockCreated: 3,
      },
    },
  },
};

export const NETWORK_ID = 4337;
